<template>
  <div class="content voucher-list">
    <div class="form-content">
      <div style="margin-bottom: 1%">
        <a-button type="primary" @click="createHandle">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新增凭证') }}
        </a-button>
      </div>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input allow-clear v-model.trim="formData.voucherSn" :placeholder="$t('凭证单据号')" />
        </a-col>
        <a-col :span="6">
          <a-input allow-clear v-model.trim="formData.voucherNo" :placeholder="$t('凭证号')" />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :list="journal_voucher_type"
            :code.sync="formData.voucherType"
            :placeholder="$t('凭证字')"
          />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            id="effectiveTime"
            :needhms="false"
            @get_date="chooseAccountingDate"
            :placeholder="$t(`记账日期`)"
            :start-time="formData.accountingDateStart"
            :end-time="formData.accountingDateEnd"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 10px">
        <a-col :span="6">
          <DateRangePicker
            id="effectiveTime"
            :needhms="false"
            @get_date="chooseBizTime"
            :placeholder="$t(`业务日期`)"
            :start-time="formData.bizTimeStart"
            :end-time="formData.bizTimeEnd"
          />
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            api="queryAccountingCalendarDetailPage"
            valueName="accounting_period_code"
            labelName="accounting_period_name"
            :code.sync="formData.accountingPeriod"
            :placeholer="$t(`会计期间`)"
          />
        </a-col>
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.sourceDocSn"
            :placeholder="$t('来源单据单号')"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :list="document_type"
            :code.sync="formData.sourceDocType"
            :placeholder="$t('来源单据类型')"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 10px">
        <a-col :span="6">
          <CommonSelect
            :isBu="true"
            :list="$store.state.settlementBuList"
            :placeholder="$t('结算组织')"
            :code.sync="formData.businessUnitCode"
            :name.sync="formData.businessUnitName"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="queryList(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <div class="table-content">
      <div class="table-header">
        <div class="row status-list-wrap">
          <a-tabs default-active-key="" @change="setSearchStatus">
            <a-tab-pane key="">
              <template slot="tab">
                <span>{{ $t('全部') }}</span>
              </template>
            </a-tab-pane>
            <a-tab-pane v-for="item in journal_voucher_status" :key="item.code">
              <template slot="tab">
                <span>{{ $t(item.name) }}</span>
              </template>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div class="btns flex-ct">
          <OperateBtn
            api="checkVoucher"
            :text="$t('审核')"
            :params="{
              idList: selectedRowKeys,
            }"
            :disabled="selectedRowKeys.length === 0"
            @success="queryList"
          />
          <TableSetting :columns="columns" @change="changeColumns" />
        </div>
      </div>
      <!-- expandedRowRender 展开有问题 不能用CommonTable -->
      <div class="table-body">
        <a-table
          size="small"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="showColumns"
          :loading="loading"
          :dataSource="tableData"
          :pagination="false"
          rowKey="id"
          :scroll="{ x: 1500, y: scrollHeight }"
        >
          <template slot="no" slot-scope="text, record, index">
            <span>{{ index + 1 }}</span>
          </template>
          <template slot="voucherSn" slot-scope="text, record">
            <span class="text-link" @click="viewDetail(record)">{{ record.voucher_sn }}</span>
          </template>
          <template slot="source_doc_sn" slot-scope="text, record">
            <template v-if="record.source_doc_sn?.length">
              <div
                class="text-link"
                v-for="(item, index) in record.source_doc_sn.split(',')"
                :key="item"
                @click="viewSourceDetail(record, index)"
              >
                {{ item }}
              </div>
            </template>
          </template>

          <template slot="expandedRowRender" slot-scope="record">
            <CommonTable
              style="background: #fff"
              :dataSource="record.detail_list"
              :columns="detailColumns"
              :showPagination="false"
              :showSetting="false"
              rowKey="id"
            ></CommonTable>
          </template>
        </a-table>
      </div>
      <div class="table-footer mt10">
        <pagination
          :pageNo="pageNo"
          :length="pageSize"
          :total-count="total"
          @paginate="paginate"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import { add } from '@/common/number'
import DateRangePicker from '@component/dateRangePicker'
import pagination from '@/component/pagination.vue'
import TableSetting from '@/component/tableSetting.vue'
import { jumpSoucePage } from '@/common'
export default {
  name: 'VoucherList',
  components: { pagination, DateRangePicker, TableSetting },
  data() {
    return {
      formData: {},
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      total: 0,
      pageNo: 1,
      pageSize: 20,

      columns: [
        {
          title: this.$t('凭证单据号'),
          dataIndex: 'voucher_sn',
          scopedSlots: {
            customRender: 'voucherSn',
          },
          width: 200,
        },
        {
          title: this.$t('账簿'),
          dataIndex: 'account_set_name',
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'business_unit_name',
          width: 150,
        },
        {
          title: this.$t('凭证状态'),
          dataIndex: 'status',
          customRender: (text) => this.journalVoucherStatusMapping[text],
        },
        {
          title: this.$t('期间'),
          dataIndex: 'accounting_period_name',
        },
        {
          title: this.$t('来源单据'),
          dataIndex: 'source_doc_type',
          customRender: (text) => this.documentTypeMapping[text],
        },
        {
          title: this.$t('来源单据单号'),
          dataIndex: 'source_doc_sn',
          scopedSlots: {
            customRender: 'source_doc_sn',
          },
          width: 200,
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucher_no',
        },
        {
          title: this.$t('凭证字'),
          dataIndex: 'voucher_type',
          customRender: (text) => this.journalVoucherTypeMapping[text],
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'biz_date',
          customRender: (text) => text?.split(' ')?.[0],
        },
        {
          title: this.$t('记账日期'),
          dataIndex: 'accounting_date',
          customRender: (text) => text?.split(' ')?.[0],
        },
        {
          title: this.$t('借方合计'),
          dataIndex: 'debit_amount',
        },
        {
          title: this.$t('贷方合计'),
          dataIndex: 'credit_amount',
        },
      ],
    }
  },
  activated() {
    this.queryList()
  },
  computed: {
    ...mapState(['journal_voucher_type', 'journal_voucher_status', 'document_type']),
    ...mapGetters([
      'currencyListMapping',
      'documentTypeMapping',
      'journalVoucherTypeMapping',
      'journalVoucherStatusMapping',
    ]),
    showColumns() {
      return [
        {
          title: '#',
          align: 'center',
          key: 'NO',
          width: 60,
          scopedSlots: {
            customRender: 'no',
          },
        },
        ...this.columns,
      ]
    },
    detailColumns() {
      return [
        { title: this.$t('科目编码'), dataIndex: 'account_code' },
        { title: this.$t('科目名称'), dataIndex: 'account_name' },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimension_list',
          customRender: (text) =>
            text
              ?.map(
                (item) =>
                  `${item.accounting_dimension_name}:${item.accounting_dimension_value_name}`
              )
              .join(', '),
        },
        {
          title: this.$t('币别'),
          dataIndex: 'currency',
          customRender: (text) => this.currencyListMapping[text],
        },
        {
          title: this.$t('借方'),
          dataIndex: 'debit_amount',
          customRender: (text, record) => this.showPrice(text, record.currency),
        },
        {
          title: this.$t('贷方'),
          dataIndex: 'credit_amount',
          customRender: (text, record) => this.showPrice(text, record.currency),
        },
      ]
    },
    scrollHeight() {
      return window.innerHeight - 520
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.queryList()
    },
    showPrice(num, currency) {
      if (num !== undefined) {
        return this.$currencySymbol(num, currency)
      }
    },
    chooseBizTime(date) {
      this.formData.bizTimeStart = date.start_time
      this.formData.bizTimeEnd = date.end_time
    },
    chooseAccountingDate(date) {
      this.formData.accountingDateStart = date.start_time
      this.formData.accountingDateEnd = date.end_time
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    setSearchStatus(status) {
      this.formData.status = status
      this.pageNo = 1
      this.selectedRowKeys = []
      this.$nextTick(() => {
        this.queryList()
      })
    },
    queryList(pageNo = 0) {
      if (pageNo) {
        this.pageNo = pageNo
      }
      this.loading = true
      http({
        url: api.getVoucherList,
        data: {
          page_no: this.pageNo,
          page_size: this.pageSize,
          ...this.formData,
        },
        success: (res) => {
          let list = res.result.list
          this.tableData = list.map((item) => {
            let debit_amount = 0,
              credit_amount = 0,
              currency
            item.detail_list.forEach((elem) => {
              currency = elem.currency
              if (elem.direction === 'CREDIT') {
                elem.credit_amount = elem.amount
              } else {
                elem.debit_amount = elem.amount
              }
              credit_amount = add(credit_amount, elem.credit_amount || 0)
              debit_amount = add(debit_amount, elem.debit_amount || 0)
            })
            item.debit_amount = this.showPrice(debit_amount, currency) || 0
            item.credit_amount = this.showPrice(credit_amount, currency) || 0

            item.detail_list.push({
              type: 'summary',
              debit_amount: item.debit_amount || '0',
              credit_amount: item.credit_amount || '0',
            })
            return item
          })
          this.total = res.result.total
          this.loading = false
        },
        fail: (err) => {
          this.$message.error(err.msg || this.$t('出错了'))
          this.loading = false
        },
      })
    },
    createHandle() {
      this.$router.push({
        path: '/voucherManage/voucherDetail',
      })
    },
    viewDetail(record) {
      this.addPane({
        view: 'voucherDetail/' + record.voucher_sn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.voucher_sn,
        },
        route: `/voucherManage/voucherDetail?id=${record.id}&sn=${record.voucher_sn}`,
      })
    },
    viewSourceDetail(record, index) {
      let source_doc_sn_list = record.source_doc_sn.split(','),
        source_doc_id_list = record.source_doc_id.split(',')
      jumpSoucePage(source_doc_sn_list[index], source_doc_id_list[index])
    },
    changeColumns(list) {
      this.columns = list
    },
  },
}
</script>
